









































import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'

import {Mixins, Component, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import MyWalletInfo from '@/components/myWallet/MyWalletSection.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import MyTokensNftsSection from '@/components/myWallet/MyWalletTokensNftsSection.vue'
import {bsNeo3} from '@/libs/bsNeo3'
import {BalanceResponse} from '@cityofzion/blockchain-service'
import MyTokensGasCalculatorSection from '@/components/myWallet/MyWalletGasCalculatorSection.vue'
import MyTokensGetGasSection from '@/components/myWallet/MyWalletGetGasSection.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {$} from '@/facade'
import {WalletsStatus} from '@/model/wallets/types/WalletTypes'

@Component({
  components: {
    MyTokensGetGasSection,
    MyTokensGasCalculatorSection,
    MyTokensNftsSection,
    MyWalletInfo,
    AppFooter,
    NavbarMenu,
  },
})
export default class MyWalletView extends Mixins(MixinScreenSize) {
  tokensTableItemsCollection = new TokensTableItemsCollection().noPagination()

  balanceResponse: BalanceResponse[] = []
  myWalletTokensTableItems: MyWalletTokensTableItem[] = []

  lastPriceUpdated: string = ''

  walletIsStarted = false

  isRefreshing = false

  get neoBalance() {
    return Number(
      this.balanceResponse.find(item => item.token.symbol === 'NEO')?.amount ??
        0
    )
  }

  get totalBalanceAmountInDollar() {
    return this.myWalletTokensTableItems.reduce(
      (acc, item) => acc + item.amountInDollar,
      0
    )
  }

  get totalBalancePercentageVariation() {
    return 0 // TODO: Implement this in the future, probably on #86dukev19
  }

  mounted() {
    this.onWalletStatusChange(this.$store.state.walletAdapter.walletsStatus)
  }

  async populateAccountBalance(address: string) {
    try {
      this.balanceResponse = await bsNeo3.blockchainDataService.getBalance(
        address
      )
    } catch (error) {
      this.$toast.abort('Error fetching account balance. Please try again.')
    }
  }

  @Watch('$store.state.walletAdapter.address')
  onAddressChange(address: string | null) {
    if (!address && this.walletIsStarted) {
      this.$router.push('/')
    }
  }

  @Watch('$store.state.walletAdapter.walletsStatus')
  async onWalletStatusChange(status: WalletsStatus) {
    const walletIsConnectedWithError = Object.values(status).some(
      statusName => statusName === 'error'
    )
    if (walletIsConnectedWithError) {
      this.$toast.abort('Error connecting to wallet. Please try again.')
      await this.$router.push('/')
      return
    }

    const walletIsStarted = Object.values(status).some(
      statusName => statusName === 'started'
    )
    if (!walletIsStarted) return

    await this.populateResources()

    this.walletIsStarted = true
  }

  async populateResources() {
    $.await.init('softQuery')

    const address = this.$walletAdapter.address
    if (!address) {
      await this.$router.push('/')
      return
    }

    const promises: Promise<unknown>[] = [
      this.populateAccountBalance(address),
      this.tokensTableItemsCollection.listTokensTableItems(),
    ]

    await Promise.all(promises)

    this.convertToMyWalletTokensTableItems()

    $.await.done('softQuery')
  }

  convertToMyWalletTokensTableItems() {
    const myWalletTokensBeforeUpdate: MyWalletTokensTableItem[] = []

    this.balanceResponse.forEach(item => {
      if (item.amount === '0') return

      const token = this.tokensTableItemsCollection.items.find(
        token => token.marketInformation?.hash === item.token.hash
      )

      if (!token) return

      const myWalletTokensTableItem = new MyWalletTokensTableItem()

      myWalletTokensTableItem.imageUrl = token.imageUrl
      myWalletTokensTableItem.symbol = token.symbol
      myWalletTokensTableItem.marketInformation = token.marketInformation
      myWalletTokensTableItem.amount = Number(item.amount)

      myWalletTokensBeforeUpdate.push(myWalletTokensTableItem)
    })

    this.myWalletTokensTableItems = myWalletTokensBeforeUpdate
    this.lastPriceUpdated = this.tokensTableItemsCollection.lastTimeUpdatedFormatted
  }

  async handleRefreshData() {
    this.isRefreshing = true

    await this.populateResources()

    this.isRefreshing = false
  }
}
